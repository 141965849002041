<template>
  <sgv-table
    :items="orders"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <template slot="option" v-if="options.toolbar === 'itemData'">
      <DetailOrderWithholdingInput
        class="mb-3"
        :docId="docId"
        :docType="docType"
        :templateType="templateType">
      </DetailOrderWithholdingInput>

      <DetailOrderSocialSecurityInput
        class="mb-3"
        :docId="docId"
        :docType="docType"
        :templateType="templateType">
      </DetailOrderSocialSecurityInput>
    </template>

    <template slot-scope="row">
      <DetailOrderSubOrderItem
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :row="row"
        :isDestroyable="!formData.approvedAt">
      </DetailOrderSubOrderItem>
    </template>
  </sgv-table>
</template>

<script>
import DetailOrderSubOrderItem from './DetailOrderSubOrderItem.vue'
import DetailOrderWithholdingInput from './DetailOrderWithholdingInput.vue'
import DetailOrderSocialSecurityInput from './DetailOrderSocialSecurityInput.vue'
import {
  LIST_ORDER,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_DESTROYED,
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
    }
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: `ราคา`, value: 'totalPrice'}
      ]
    },
    toolbars() {
      let arr = []

      if (!this.formData.approvedAt && this.docId > 0) {
        arr.push({ value: 'itemData', icon: 'plus', class: 'text-success' })
      }

      return arr
    },
    orderMapped() {
      return [
        ...this.orders.filter(v => v.type === 'withholding'),
        ...this.orders.filter(v => v.type === 'socialSecurity'),
      ]
    }
  },
  apollo: {
    orders: {
      query() {
        return LIST_ORDER(this.templateType)
      },
      variables() {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      skip() {
        return !(this.docId > 0)
      },
      subscribeToMore: [
        {
          document() {
            return WATCH_ORDER_CREATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery(previous, { subscriptionData }) {
            const newOrder = subscriptionData.data.orderCreated
            const orders = [
              ...previous.orders,
              newOrder
            ]

            return { ...previous, orders }
          }
        },
        {
          document() {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery(previous, { subscriptionData }) {
            const destroyedOrder = subscriptionData.data.orderDestroyed
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === destroyedOrder.id)

            if (idx !== -1) orders.splice(idx, 1)

            return { ...previous, orders }
          }
        }
      ]
    }
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    }
  },
  components: {
    DetailOrderSubOrderItem,
    DetailOrderWithholdingInput,
    DetailOrderSocialSecurityInput
  }
}
</script>

<style lang="css">
</style>
