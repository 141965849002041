<template>
  <div>
    <div class="form-row">
      <sgv-input-select
        label="ประเภท"
        placeholder="เลือกรายการ"
        class="col-6"
        :options="topics"
        select="value"
        v-model="formData.withholdingTopic"
        :validations="[
          { text: 'required!', value: $v.formData.withholdingTopic.$dirty && $v.formData.withholdingTopic.$error }
        ]">
        <template slot-scope="option">
          {{ option.text }}
        </template>
      </sgv-input-select>

      <sgv-input-text
        class="col-6"
        label="รายละเอียด"
        v-model="formData.withholdingName"
        :validations="[
          { text: 'required!', value: $v.formData.withholdingName.$dirty && $v.formData.withholdingName.$error }
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-select
        label="ผู้จ่ายเงิน"
        placeholder="เลือกรายการ"
        class="col-6"
        :options="payers"
        select="value"
        v-model="formData.withholdingType"
        :validations="[
          { text: 'required!', value: $v.formData.withholdingType.$dirty && $v.formData.withholdingType.$error }
        ]">
        <template slot-scope="option">
          {{ option.text }}
        </template>
      </sgv-input-select>

      <sgv-input-text
        v-if="formData.withholdingType === 4"
        class="col-6"
        label="หมายเหตุ"
        v-model="formData.withholdingRemark">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-currency
        class="col-6 col-sm-4"
        label="จำนวนเงินที่จ่าย"
        v-model="formData.withholdingPrice"
        :precision="2"
        :validations="[
          { text: 'จำนวนมากกว่า 0', value: $v.formData.withholdingPrice.$dirty && !$v.formData.withholdingPrice.minValue }
        ]">
      </sgv-input-currency>

      <sgv-input-currency
        class="col-6 col-sm-4"
        label="ภาษีที่หัก"
        v-model="formData.price"
        :precision="2"
        :validations="[
          { text: 'จำนวนมากกว่า 0', value: $v.formData.price.$dirty && !$v.formData.price.minValue }
        ]">
      </sgv-input-currency>
    </div>

    <button type="button" class="btn btn-success" @click="createOrder">เพิ่ม</button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CREATE_ORDER_WITHHOLDING } from './graph/order'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        withholdingType: 1,
        withholdingTopic: 6,
        withholdingName: '',
        withholdingRemark: '',
        withholdingPrice: 0,
        price: 0
      },
      payers: [
        { text: 'หักภาษี ณ ที่จ่าย', value: 1 },
        { text: 'ออกภาษีให้ครั้งเดียว', value: 2 },
        { text: 'ออกให้ตลอดไป', value: 3 },
        { text: 'อื่นๆ', value: 4 },
      ],
      topics: [
        { text: '1.เงินเดือน ค่าจ้าง โบนัส ม.40(1)', value: 1 },
        { text: '2.ค่าธรรมเนียม ค่านายหน้า ม.40(2)', value: 2 },
        { text: '3.ค่าแห่งลิขสิทธิ์ ม.40(3)', value: 3 },
        { text: '5.มาตรา 3เตรส', value: 5 },
        { text: '6.อื่นๆ', value: 6 },
      ],
    }
  },
  validations () {
    return {
      formData: {
        withholdingType: { required },
        withholdingTopic: { required },
        withholdingName: { required },
        withholdingPrice: { minValue: this.minValue },
        price: { minValue: this.minValue },
      }
    }
  },
  methods: {
    minValue(value) {
      return value >= 0
    },
    createOrder() {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_WITHHOLDING(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.formData
        }
      })
        .then(() => {
          this.setDefault()
          this.$toasted.global.success("เพิ่มสำเร็จ")
        })
        .catch(this.$toasted.global.error)
    },
    setDefault() {
      this.$v.formData.$reset()
    },
  }
}
</script>