<template>
  <div>
    <div class="form-row">
      <sgv-input-currency
        class="col-6 col-sm-4"
        label="เงินสะสมเข้ากองทุนประกันสังคม"
        v-model="formData.price"
        :precision="2"
        :validations="[
          { text: 'จำนวนมากกว่า 0', value: $v.formData.price.$dirty && !$v.formData.price.minValue }
        ]">
      </sgv-input-currency>
    </div>

    <button type="button" class="btn btn-success" @click="createOrder">เพิ่ม</button>
  </div>
</template>

<script>
import { CREATE_ORDER_SOCIAL_SECURITY } from './graph/order'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        price: 0
      },
    }
  },
  validations () {
    return {
      formData: {
        price: { minValue: this.minValue },
      }
    }
  },
  methods: {
    minValue(value) {
      return value >= 0
    },
    createOrder() {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_SOCIAL_SECURITY(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.formData
        }
      })
        .then(() => {
          this.setDefault()
          this.$toasted.global.success("เพิ่มสำเร็จ")
        })
        .catch(this.$toasted.global.error)
    },
    setDefault() {
      this.$v.formData.$reset()
    },
  }
}
</script>