<template>
  <tr>
    <td class="no-wrap" v-if="hidden.name">
      <fa
        v-if="isDestroyable"
        icon="trash"
        class="text-danger pointer mr-1"
        @click="destroyOrder(item.id)">
      </fa>

      <span @click="emitSelected(item.id)" class="pointer">
        {{item.id}}
      </span>
    </td>
    <td v-if="hidden.name">
      <span style="white-space: pre-line;">{{ item.name }}</span>
      <small v-if="withholdingType">
        <div class="text-info">
          {{item.withholdingTopic}}.
          {{item.withholdingName}}
          ({{item.withholdingPrice}})
        </div>
        <div class="text-info">
          {{withholdingType.text}}
        </div>
        <div v-if="item.withholdingRemark" class="text-danger">
          หมายเหตุ: {{item.withholdingRemark}}
        </div>
      </small>
    </td>
    <td v-if="hidden.totalPrice">
      {{ item.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
import { DESTROY_ORDER } from './graph/order'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    isDestroyable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      withholdingTypes: [
        {text: 'หักภาษี ณ ที่จ่าย', value: 1},
        {text: 'ออกภาษีให้ครั้งเดียว', value: 2},
        {text: 'ออกให้ตลอดไป', value: 3},
        {text: 'อื่นๆ', value: 4},
      ]
    }
  },
  computed: {
    withholdingType () {
      return this.withholdingTypes.find(v => v.value === this.item.withholdingType)
    },
    item () {
      return this.row.item
    },
    hidden () {
      return this.row.hidden
    }
  },
  methods: {
    destroyOrder(id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
        .then(() => {
          this.$toasted.global.success("ลบสำเร็จ")
        })
        .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
