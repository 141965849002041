var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-row"},[_c('sgv-input-select',{staticClass:"col-6",attrs:{"label":"ประเภท","placeholder":"เลือกรายการ","options":_vm.topics,"select":"value","validations":[
        { text: 'required!', value: _vm.$v.formData.withholdingTopic.$dirty && _vm.$v.formData.withholdingTopic.$error }
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}]),model:{value:(_vm.formData.withholdingTopic),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingTopic", $$v)},expression:"formData.withholdingTopic"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"รายละเอียด","validations":[
        { text: 'required!', value: _vm.$v.formData.withholdingName.$dirty && _vm.$v.formData.withholdingName.$error }
      ]},model:{value:(_vm.formData.withholdingName),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingName", $$v)},expression:"formData.withholdingName"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-select',{staticClass:"col-6",attrs:{"label":"ผู้จ่ายเงิน","placeholder":"เลือกรายการ","options":_vm.payers,"select":"value","validations":[
        { text: 'required!', value: _vm.$v.formData.withholdingType.$dirty && _vm.$v.formData.withholdingType.$error }
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}]),model:{value:(_vm.formData.withholdingType),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingType", $$v)},expression:"formData.withholdingType"}}),(_vm.formData.withholdingType === 4)?_c('sgv-input-text',{staticClass:"col-6",attrs:{"label":"หมายเหตุ"},model:{value:(_vm.formData.withholdingRemark),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingRemark", $$v)},expression:"formData.withholdingRemark"}}):_vm._e()],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-currency',{staticClass:"col-6 col-sm-4",attrs:{"label":"จำนวนเงินที่จ่าย","precision":2,"validations":[
        { text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.withholdingPrice.$dirty && !_vm.$v.formData.withholdingPrice.minValue }
      ]},model:{value:(_vm.formData.withholdingPrice),callback:function ($$v) {_vm.$set(_vm.formData, "withholdingPrice", $$v)},expression:"formData.withholdingPrice"}}),_c('sgv-input-currency',{staticClass:"col-6 col-sm-4",attrs:{"label":"ภาษีที่หัก","precision":2,"validations":[
        { text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.price.$dirty && !_vm.$v.formData.price.minValue }
      ]},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}})],1),_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.createOrder}},[_vm._v("เพิ่ม")])])}
var staticRenderFns = []

export { render, staticRenderFns }