<template>
  <div class="row mt-3">
    <template>
      <div class="col-12">
        <div class="form-row">
          <sgv-input-radio
            class="col-12"
            inline
            :options="options"
            select="text"
            v-model="selected">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>
        </div>
      </div>

      <div
        class="col-12"
        v-for="option in options"
        :key="option.text">
        <component
          v-if="selected === option.text"
          :is="option.value"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :formData="formData"
          :url="option.url"
          :filename="option.filename">
        </component>
      </div>
    </template>
  </div>
</template>

<script>
import DetailPrintPdf from '@/views/doc_core/components/PrintPdf'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selected: 'เอกสารหัก ณ ที่จ่าย'
    }
  },
  computed: {
    options () {
      const arr = [
        {
          text: 'เอกสารหัก ณ ที่จ่าย',
          value: 'DetailPrintPdf',
          url: `/doc/${this.docType}/${this.docId}/print/withholding`,
          filename: `${this.formData.code}-withholding.pdf`
        },
      ]

      return arr
    }
  },
  components: {
    DetailPrintPdf
  }
}
</script>

<style lang="css" scoped>
</style>
